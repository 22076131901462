import React, { FC, ReactNode, useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import { Footer } from "./footer";

interface LayoutProps {
    children: ReactNode;  // Defines that children is expected
}

const Layout: FC<LayoutProps> = ({ children, ...rest }) => {
    const [scrollTop, setScrollTop] = useState(false);

    const handleScrollTop = () => {
        if (window.scrollY > 70) {
            setScrollTop(true);
        } else if (window.scrollY < 70) {
            setScrollTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScrollTop);
        return () => {
            window.removeEventListener("scroll", handleScrollTop);
        };
    }, [scrollTop]);

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow flex">{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;

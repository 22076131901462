exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accommodations-tsx": () => import("./../../../src/pages/accommodations.tsx" /* webpackChunkName: "component---src-pages-accommodations-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-rsvp-tsx": () => import("./../../../src/pages/rsvp.tsx" /* webpackChunkName: "component---src-pages-rsvp-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-accommodation-page-tsx": () => import("./../../../src/templates/AccommodationPage.tsx" /* webpackChunkName: "component---src-templates-accommodation-page-tsx" */)
}


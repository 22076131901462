import React, { FC } from 'react';
import { Link } from 'gatsby';
import { Heading } from '../typography';
import { Image } from '../image';

// export const Footer: FC = (props) => {
//   return (
//     <footer className="bg-gradient-to-br from-steelBlue-700 to-steelBlue-800 text-steelBlue-50">
//       <div className="container py-4 px-4 mx-auto">
//       <Link to="/">
//             {/* MauriEnergie & TP */}
//             <Image fileName="logo.svg"/>
//           </Link>
//         <br/>
//         <p className="mb-2">Tevragh Zeina sis, illot NOT 21 Bis Nouakchott - Mauritanie </p>
//         <p>
//           {/* &copy; {new Date().getFullYear()},  */}
//           + 222 33 72 10 12 - contact@maurienergie.com.  
//         </p>
//       </div>
//     </footer>
//   );
// };



export const Footer: FC = (props) => {
  return (
    <div>
      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="footer-widget footer-widget__about">
                  <a href="/">
                            <Image fileName="logo.svg"/>
                  </a>
                  <p>
                    {/* Lorem Ipsum is simply dummy text the <br /> printing and
                    setting industry. Lorm Ipsum
                    <br /> has been the text ever. */}
                    Tevragh Zeina sis, illot NOT 21 Bis
                    <br /> Nouakchott - Mauritanie
                    <br /> +222 36 16 35 35
                    <br /> contact@maurienergie.com
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="footer-widget__links-wrap">
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Compagnie</h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <a href="/our-story">À propos</a>
                      </li>
                      <li>
                        <a href="/accommodations">Produits</a>
                      </li>
                      <li>
                        <a href="/services">Services</a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Liens</h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <a href="/rsvp">Carrières</a>
                      </li>
                      <li>
                        <a href="/accommodations">Catalogue</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center">
                <div className="footer-widget">
                  <div className="footer-widget__social">
                    <a href="https://wa.me/22236163535">
                      <i className="fab fa-whatsapp"></i>
                    </a>
                    <a href="/our-story">
                      <i className="fa fa-envelope"></i>
                    </a>
                    <a>
                      <i className="fab fa-facebook-square"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="site-footer__bottom">
          <div className="container text-center">
            <p>© copyright 2020 by Layerdrops.com</p>
          </div>
        </div> */}
      </footer>
    </div>
  );
};

export default Footer;
